import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/Seo';
import { Container, serializers } from './PageTemplate';
export default function UpdatePage({ data, pageContext }) {
  const { sanityUpdate } = data;
  const { slug } = pageContext;
  const { date } = sanityUpdate

  return (
    <>
      <SEO
        title={sanityUpdate.title}
      />
      <Container
        className="t-pb-12"
      >
        <section className="t-h-1/2screen t-bg-transparent t-flex t-items-start t-flex-col t-container t-mx-auto t-justify-center">
          <div className="t-relative">
            <p className="t-bg-purple-1000-alpha t-text-white t-text-8xl t-leading-none t-p-4 t-uppercase t-font-bold">
              News & Updates
            </p>
          </div>
        </section>
        <BlockContent
          blocks={sanityUpdate._rawContent}
          projectId={process.env.GATSBY_SANITY_PROJECT_ID}
          dataset={process.env.GATSBY_SANITY_DATASET}
          serializers={serializers(slug)}
        />
      </Container>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityUpdate(slug: {
      current: { eq: $slug }
    }) {
      title
      _rawContent(resolveReferences: {maxDepth: 10})
    }
  }
`;
